.instrument-table {
  display: flex;
  overflow: hidden;
  flex-basis: 87.5%;
}

.instrument-table__row {
  display: flex;
  flex-flow: column;
  flex: 1;
  padding-left: 1.5rem;
  justify-content: center;
  height: var(--instrument-table__row-height);
  border-right: 1px solid var(--instrument-table__row-separator-color);
  border-bottom: 1px solid transparent;
}

.instrument-table__value {
  font-size: 2rem;
  font-weight: bold;
  white-space: nowrap;
  justify-content: center;
  color: var(--component__color-primary);
}

.instrument-table__row:last-child {
  border-right: none;
}

.instrument-table__title {
  font-size: 1.4rem;
  white-space: nowrap;
  color: var(--component__color-secondary);
}

.instrument-table__value--24h-positive {
  color: var(--exchange-colors__buy);
}

.instrument-table__value--24h-negative {
  color: var(--exchange-colors__sell);
}

.instrument-table__value--bid {
  color: var(--exchange-colors__buy);
}

.instrument-table__value--ask {
  color: var(--exchange-colors__sell);
}

.chart-overview-layout-2-top__row {
  height: 7.8rem;
  padding-left: 2.8rem
}

.chart-overview-layout-2-bottom__row {
  height: 7.8rem;
  padding-left: 3.1rem
}

.chart-overview-layout-2-top__value {
  font-size: 2.4rem;
}

.chart-overview-layout-2-bottom__value {
  font-size: 1.6rem;
}

.chart-overview-layout-2-top__title,
.chart-overview-layout-2-bottom__title {
  font-size: 1.2rem;
}

/* MEDIA QUERIES */
@media only screen and (max-width: 1200px) {
  .instrument-table__row--24h-low,
  .instrument-table__row--24h-high {
    display: none;
  }
}
@media only screen and (max-width: 968px) {

  .instrument-table__value {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .instrument-row {
    flex-direction: column;
  }

  .instrument-row .instrument-table {
    flex-wrap: wrap;
    /* flex-wrap: nowrap; */

    width: 100%;
    flex-basis: 100%;
    background: var(--table__bg-color);
  }

  .instrument-row .instrument-table .instrument-table__row {
    border-bottom: 1px solid var(--instrument-table__row-separator-color);
    display: flex;
  }

  .instrument-table__row {
    /* width: 50%; */
    width: auto;
    height: auto;
    flex: unset;
    padding-left: 3rem;
  }

  .instrument-table__row:last-child{
    width: 100%;
  }
}

/* Small Devices, Tablets */
@media only screen and (min-width: 768px) {
}
@media only screen and (max-width: 768px) {
  .instrument-table {
    flex-basis: 75%;
  }
  .instrument-table__row--hideable {
    display: none;
  }
}

/* Extra Small Devices */
@media only screen and (max-width: 480px) {
  .instrument-table__row--hideable {
    display: flex;
  }
  .instrument-table {
    flex-basis: 100%;
  }
  .instrument-row .instrument-table {
    flex-direction: row;
    flex-wrap: wrap;
  }
  .instrument-table__row {
    padding-left: 2rem;
    width: auto;
    flex: 1 1;
    height: auto;
  }
}